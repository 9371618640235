@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@fontsource/reem-kufi'; /* Default Regular Weight */
@import '@fontsource/reem-kufi/400.css'; /* Regular */
@import '@fontsource/reem-kufi/500.css'; /* Medium */
@import '@fontsource/reem-kufi/700.css'; /* Bold */
@import "@fontsource/mulish";
@import "@fontsource/mulish/400.css";
@import "@fontsource/mulish/400-italic.css";
body {
  margin: 0px !important;
    font-family: 'Reem Kufi', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* In your index.css or App.css */
.font-medium {
  font-family: 'Reem Kufi', sans-serif;
  font-weight: 500;
}

.font-semi {
  font-family: 'Reem Kufi', sans-serif;
  font-weight: 600;
}

.font-semibold {
  font-family: 'Reem Kufi', sans-serif;
  font-weight: 700;
}

.font-bold {
  font-family: 'Reem Kufi', sans-serif;
  font-weight: bold;
}
.font-mulish {
  font-family: 'Mulish', sans-serif !important;
}
.text-gray-7 {
  color: #8c8c8c !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
